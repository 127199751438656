/*
 * JSON:API support for talking to MN3 Server
 */
import axios from "axios";
import store from '../store'
import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Preferences } from '@capacitor/preferences';

window.mn3_build_id = process.env.VUE_APP_MN3_BUILD_ID;

function noEndSlash(str){
	return str.endsWith("/") ? str.slice(0, -1) : str;
}

const API_CONFIGS = {
	user: {
		isLoggedIn(state) {
			return state.isLoggedIn;
		},
		makeBaseUrl(includeMembershipId){
			if(!store.state.memberId){
				throw Error("No member ID set");
			}

            let ret = noEndSlash(window.mn3_config.apiBaseUrl + window.mn3_config.fiPath) +
                '/members/' + store.state.memberId + '/';
            return ret;
		}
	},
	member: {
		isLoggedIn(state) {
			return state.isLoggedIn;
		},
		makeBaseUrl(includeMembershipId, includeMemberships){
			if(!store.state.memberId){
				throw Error("No member ID set");
			}
            let ret = noEndSlash(window.mn3_config.apiBaseUrl + window.mn3_config.fiPath); 
            if(includeMemberships){
                ret = noEndSlash(ret) + '/members/' + store.state.memberId + '/';
            }
            if(includeMembershipId){
                ret = noEndSlash(ret) + '/memberships/' + store.state.membershipId + '/';
            }
            return ret;
		}
	},
	fi: {
		isLoggedIn(state) {
			return state.isLoggedInCuAdmin;// || state.isLoggedInAppAdmin;
		}
	},
	fi_public: {
		isLoggedIn(state) {
			return true;
		}
	},
	root: {
		isLoggedIn(state) {
			return state.isLoggedInAppAdmin;
		},
		makeBaseUrl(){
			return window.mn3_config.apiBaseUrl;
		}
	}
}

var PLATFORM = Capacitor.getPlatform();
console.log('PLATFORM', PLATFORM);
var IS_APP = PLATFORM != 'web';
var HEADERS = {
	'X-Requested-With': 'XMLHttpRequest',
	'X-MN3-Platform': PLATFORM,
	'X-MN3-UI-Ver': window.mn3_build_id,
}
var sesTimeout = null;
var authApi = null;



async function update_app(){

    console.log('update_app()');
    
    // Skip update if at current version already
    if(store.state.serverBundleId == window.mn3_build_id){
        console.log('update skipped, at current version');
        return;
    }
    
    // Check if long enough to request another update
    let updateAppExpire = localStorage.getItem('update_app_expire');
    if(updateAppExpire == null || updateAppExpire == undefined)
        updateAppExpire = 0;

    let now = Math.floor(Date.now() / 1000);
    if(now >= updateAppExpire){

        console.log('Update app check: ', now, updateAppExpire);

        localStorage.setItem('update_app_expire', Math.floor(Date.now() / 1000) + (10 * 60));
        store.commit('setIsLoading', true);

        if(Capacitor.isNativePlatform()){
            let url = process.env.VUE_APP_MN3_DIST_URL
            if(!window.mn3_prod) url = process.env.VUE_APP_MN3_STAGE_DIST_URL

            store.commit('setUpdateStatus', 'downloading');
            const version = await CapacitorUpdater.download({ version: store.state.serverBundleId + '', url: url });
            console.log(version);
            store.commit('setUpdateStatus', 'installing');
            await CapacitorUpdater.set(version)
        }else{
            //web update is just to refresh the page
            location.reload();
        }
    }else{
        console.log('Update attempt skipped, last attempt too recent. ', updateAppExpire);
    }
}



function load_storage(){
	if(!IS_APP) return Promise.resolve();
	return Promise.all([
		Preferences.get({key: "auth"}).then(res=>{
			//console.log('auth storage result', res);
			if(res.value){
				HEADERS['Authorization'] = res.value;
			}
		}),
		Preferences.get({key: "device"}).then(res=>{
			//console.log('device result', res);
			if(res.value){
				HEADERS['X-MN3-Device'] = res.value;
			}
		})
	]);

}

function common_request_interceptor(config){
	//HEADERS is copied on load so we have to set it on each request.
	if(HEADERS['Authorization']){
		config.headers.Authorization = HEADERS['Authorization'];
	}
	if(HEADERS['X-MN3-Device']){
		config.headers['X-MN3-Device'] = HEADERS['X-MN3-Device'];
	}
}

function store_device_id(device){
	if(IS_APP && device){
		HEADERS['X-MN3-Device'] = device;
		Preferences.set({key: "device", value: device});
	}
}

function on_session_timeout(){
	console.log('on_session_timeout')
	if(authApi){
        store.commit('setShowInactiveNotice', true);
		authApi.logout();
	}
}

var _first_response = true;

function common_response_interceptor(response){
	var exp = response.headers['x-mn3-token-exp'];
	if(typeof(exp) != "undefined"){
		if(sesTimeout){
			clearTimeout(sesTimeout);
		}
		exp = (parseInt(exp) - 10) * 1000;
		//console.log("setTimeout(on_session_timeout, " + exp + ")");
		if(exp > 1000){
			sesTimeout = setTimeout(on_session_timeout, exp);
		}
	}

    var cver = response.headers['x-mn3-ui-cur-ver'];
    if(typeof(cver) != "undefined")
        store.state.serverBundleId = cver;

	var rver = response.headers['x-mn3-ui-req-ver'];
	if(typeof(rver) != "undefined" && rver != window.mn3_build_id){
		//handle a required update
		update_app();
	}else{
		//else we check for a non-required update
		if(typeof(cver) != "undefined" && cver != window.mn3_build_id){
			//for non-required we only check the first request
			if(_first_response){
				update_app();
			}
		}
	}
	_first_response = false;

	if(!IS_APP) return;
	let t = response.headers['x-mn3-token'];
	//console.log('common', t, response);
	if(typeof(t) != "undefined"){
		if(t){
			//console.log('setting tok', t);
			HEADERS['Authorization'] = "Bearer " + t;
		}else{
			//console.log('unsetting tok', t);
			HEADERS['Authorization'] = undefined;
		}
		Preferences.set({key: "auth", value: HEADERS['Authorization']});
	}
}

function make_api(app, apiConfig, authApi){
	if(!window.mn3_config) return null;
	var api = axios.create({
		baseURL: window.mn3_config.apiBaseUrl + window.mn3_config.fiPath,
		withCredentials: true,
		headers: HEADERS, 
	});
	api.interceptors.request.use(function (config) {
		console.log('intercepted request', config);
        if(!apiConfig.isLoggedIn(store.state) && config.url != 'loanapp'){
            return Promise.reject(Error("Must log in first."));
        }

		store.commit('setIsBusy', true);
		common_request_interceptor(config);
		if(apiConfig.makeBaseUrl){
			try{
                //Get the root of the url posting to
                let root = config.url.split('/')[0];

                // if fetching devices or deleting a saved device, the membership id
                //  past of the url is not included
                let includeMembershipId = true
                let includeeMemberships = true

                //Devices interactoin doesn't 
                if(config.url == 'devices') 
                    includeMembershipId = false
                else if(config.method == 'delete'){
                    if(root == 'devices'){
                        includeMembershipId = false;
                    }
                }
                //Messaging AP, don't include memberships 
                else if(root == 'messaging' && config.method == 'post'){
                    includeeMemberships = false;
                    includeMembershipId = false;
                }

				config.baseURL = apiConfig.makeBaseUrl(includeMembershipId, includeeMemberships);
			}catch(e){
				return Promise.reject(e);
			}
			console.log('burl', config.baseURL);
		}
		return config;
	});
	api.interceptors.response.use(function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		store.commit('setIsBusy', false);
		common_response_interceptor(response);
		return response;
	  }, function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		store.commit('setIsBusy', false);
		console.log('err', error);
		if(error.response){
			if(error.response.status == 401){
				//user is not logged in but should be
				authApi.logout()
				return Promise.reject(error);
			}
		}
		return Promise.reject(error);
	});

	return api;
}

function make_auth_api(app){
	if(!window.mn3_config) return null;
	var api = axios.create({
		baseURL: window.mn3_config.apiBaseUrl,
		withCredentials: true,
		headers: HEADERS,
	});
	api.interceptors.request.use(function (config) {
		store.commit('setIsBusy', true);
		common_request_interceptor(config);
		return config;
	});
	api.interceptors.response.use(function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		store.commit('setIsBusy', false);
		common_response_interceptor(response);
		return response;
	  }, function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		store.commit('setIsBusy', false);
		return Promise.reject(error);
	});

	api._storeMember = function(mid, mship){
		api.get(window.mn3_config.fiPath + "members/" + mid + "/memberships").then(r=>{
			if(!mship){
				let def = null;
				for(const ms in r.data.data){
					if(r.data.data[ms].attributes.default){
						def = r.data.data[ms];
						break;
					}
				}
				if(def == null) def = r.data.data[0];
				mship = def.attributes.id;
			}
			store.commit("setMembershipId", mship);
			store.commit("setMemberships", r.data.data);
			store.commit('setMemberId', mid);
			store.commit("setIsLoggedIn", true);
			store.commit("setIsLoggedInCuAdmin", false);
			store.commit("setIsLoggedInAppAdmin", false);
		})
	};

	api._authSuccess = function(data){
		store_device_id(data.device);
		if(data.type == "FIAdmin" || data.type == "SuperUser"){
			const params = new Proxy(new URLSearchParams(window.location.search), {
				  get: (searchParams, prop) => searchParams.get(prop),
			});
			if(params.explore_memb && params.explore_mship){
				//Explore Member
				api._storeMember(params.explore_memb, params.explore_mship);
			}else{
				if(data.type == "SuperUser"){
					store.commit("setIsLoggedInAppAdmin", true);
					store.commit("setIsLoggedInCuAdmin", false);
					store.commit("setIsLoggedIn", false);
				}else{
					store.commit("setPermissions", data.permissions);
					store.commit("setIsLoggedInCuAdmin", true);
					store.commit("setIsLoggedInAppAdmin", false);
					store.commit("setIsLoggedIn", false);
				}
			}
		}
		if(data.type == "FIMember"){
			let mid = data.id.split(":")[2];
			api._storeMember(mid);
		}
		store.commit("setIsLoggedInAny", true);
		store.commit('setUserTitle', data.name);
	}

	api._logout = function(){
		store.commit("setShowLogoutNotice", true);
		store.commit("setIsLoggedInCuAdmin", false);
		store.commit("setIsLoggedInAppAdmin", false);
		store.commit("setIsLoggedIn", false);
		store.commit("setIsLoggedInAny", false);
		store.commit('setUserTitle', '');
		store.commit('setMemberId', null);
	}

	api.logout = function(){
		return api.delete("auth").then(r=>{
			api._logout();
		});
	}

	api.registerStep = function(data){
        let path = window.mn3_config.fiPath+'members/register';
        return api.post(path, data).then(r=>{
			return r;
		});
	}

	api._auth = function(url, data){
		return api.post(url, data).then(r=>{
			if(r.data.status == "authenticated"){
				api._authSuccess(r.data);
			}
			return r;
		});
	}

	api.authStep = function(mode, data){
		store.commit("setShowLogoutNotice", false);
		store.commit("setShowInactiveNotice", false);

        let path = '';
        switch(mode){
            case 'members':
                path = window.mn3_config.fiPath + 'members/auth';
                break;
            
            case 'admin':
                path = window.mn3_config.fiPath + 'admins/auth';
                break;
                    
            case 'adminmn':
                path = 'admins/auth';
                break;
        }

		return api._auth(path, data);
	}

	api.authCheck = function(){
		//console.log('auth check');

        console.log('__authCheck');
        try{
            return load_storage().then(()=>{
                return api.get("auth").then(r=>{
                    if(r.data.status == "authenticated"){
                        console.log('auth is good');
                        api._authSuccess(r.data);
                    }else{
                        api._logout();
                    }
                });
            });
        }catch(ex){
            console.log('__authCheck ex catch: ', ex);
        }
	}
	return api;
}

export default {
	install(app, options){
		console.log('api install', app, options);
		app.config.globalProperties.$api_install = function(){
			authApi = app.config.globalProperties.$authapi = make_auth_api(app);
			app.config.globalProperties.$uapi = make_api(app, API_CONFIGS.user, authApi);
			app.config.globalProperties.$mapi = make_api(app, API_CONFIGS.member, authApi);
			app.config.globalProperties.$fapi = make_api(app, API_CONFIGS.fi, authApi);
			app.config.globalProperties.$pfapi = make_api(app, API_CONFIGS.fi_public, authApi);
			app.config.globalProperties.$rapi = make_api(app, API_CONFIGS.root, authApi);
		}
	}
}
