<!-- TopBar: Top navigation bar used on desktop view -->
<template>    
    <div class="flex flex-col w-full z-50">      
        <!--Site wide alert: Desktop-->        
        <Notice v-if="!$store.state.noticeClosed && hasSitewideAlert && !isAdminScreen"
            :showClose="true"
            @close="this.emit('closeNotice')"
            class="h-16"
            :title="$store.state.fi.alert.title"
            :notice="$store.state.fi.alert.alert"
            :url="$store.state.fi.alert.url"
            :level="$store.state.fi.alert.level" />  
        <div :class="`${$store.state.isIos && !hasNotch ? 'pt-6' : ''} mn3-navbar border-b-4 flex justify-between w-full items-center flex z-50`">
            
            <div class="flex justify-between items-center w-full max-w-screen-lg mx-auto">
                <!--Left Column: Nav-->
                <div class="flex flex-col flex-grow">

                    <!--Line 1: Hamb, Logo, Main Options-->
                    <div 
                        class="flex flex-row items-center">
                        <div class="ml-3" :style="`${!showAllCustomLinks ? ';' : 'transform: rotate(180deg);'} transition: transform 0.25s ease-in-out; transform-origin: center center;`">
                        <Icon v-if="$store.state.isLoggedInAny && $store.state.fi.linksPageEnabled" 
                            title="View Additional Menu Items" :id="!showAllCustomLinks ? 'hamb' : 'chev-right'" 
                            @click="showAllCustomLinks = !showAllCustomLinks" 
                            class="h-10 w-10 flex-shrink-0 cursor-pointer opacity-80 hover:opacity-100"
                             />
                            </div>
                        <img :src="logo"
                            @click="showAllCustomLinks = !showAllCustomLinks"
                            :class="`${$store.state.isLoggedInAny ? 'cursor-pointer' : ''} 
                                mn3-navbar-logo-wrapper h-auto max-h-10 w-auto flex-shrink-0 p-1 rounded-sm mr-2 my-1 flex-grow-0`" />
                        <!--Main nav options-->
                        <div v-if="$store.state.isLoggedInAny" class="flex flex-row items-center flex-grow mr-2">
                            <div v-for="screen in [this.$store.state.fi.mainNav1,this.$store.state.fi.mainNav2,this.$store.state.fi.mainNav3]" :key="screen"
                                @click="mainNavClicked(screen)"
                                :class="`${screen == '' || screen == undefined ? 'hidden' : ''} ${navbarOptionClasses(screen)} mt-1 text-base lg:text-xl uppercase px-1 tracking-tight font-bold font-sans cursor-pointer rounded-sm flex flex-row flex-grow items-center`">
                                <Icon :id="mainNavIcon(screen)" class="h-6 w-6 mr-1 flex-shrink-0" />
                                <div class="leading-4">{{mainNavTitle(screen)}}</div>
                            </div>  
                        </div>
                    </div>

                    <!--Line 2: Secondary Options-->
                    <div v-if="$store.state.isLoggedInAny"
                        class="flex flex-row mt-2">
                        <div v-for="opt in secondaryNav" :key="opt.title"
                            @click="navClicked(opt.url)"
                            :class="`text-sm uppercase px-5 py-2 font-semibold font-sans cursor-pointer opacity-80 hover:opacity-100 text-left leading-3`">
                            {{opt.title}}
                        </div>  
                        <!--If FI has RDC enabled, add capture as last option for desktop view-->
                        <div v-if="rdcEnabled"
                            @click="navClicked('/capture')"
                            :class="`text-sm uppercase px-5 py-2 font-semibold font-sans cursor-pointer opacity-80 hover:opacity-100 text-left leading-3`">
                            Remote Deposit
                        </div>  
                    </div>
            
                </div>

                <!--Right Column: Member Controls-->
                <div v-if="$store.state.isLoggedInAny"
                    class="flex flex-col w-48 flex-shrink-0 mn3-content self-stretch overflow-show" style="height: 92px">
                    <!--Non-Swapping Display-->
                    <transition name="account-select" mode="out-in">
                    <div v-if="!showAccountSelect"
                        class="flex flex-col w-full">
                        <!--Active name/swap-->
                        <div
                            @click="showAccountSelect = true"
                            :title="hasSubAccounts ? 'Account Select / Logout' : 'Account Select / Logout'" 
                            :class="`${hasSubAccounts ? 'cursor-pointer' : ''} text-sm uppercase flex flex-row font-bold items-center justify-center py-2 px-4 leading-3`">
                            <Icon :id="hasSubAccounts ? 'user-group' : 'user-circle'" class="h-8 w-8 mr-2" />
                            <div>{{activeAccountName}}</div>
                            <Icon id="chev-down" class="h-6 w-6" />
                        </div>

                        <!--Member Control Buttons-->
                        <div class="flex flex-row w-full py-0">      
                            <div v-if="$store.state.fi.messagingEnabled" class="w-1/4 flex-grow text-center opacity-80 hover:opacity-100 cursor-pointer py-2"
                                @click="memberControl('messages')"
                                :title="unread > 0 ? unread + ' Unread Messages' : 'Secure Messages'">                                
                                <Icon 
                                    :id="unread > 0 ? 'envelope-open' : 'envelope'" class="h-7 w-7 mx-auto" />
                                <div v-if="unread > 0" class="mn3-alert h-6 w-6 rounded-full p-1 text-xs relative -top-8 left-1">{{unread}}</div>
                            </div>                      
                            <div class="w-1/4 flex-grow text-center opacity-80 hover:opacity-100 cursor-pointer py-2"
                                @click="memberControl('settings')"
                                title="Account Settings">                                
                                <Icon id="cog" class="h-7 w-7 mx-auto" />
                            </div>
                            <div class="w-1/4 flex-grow text-center opacity-80 hover:opacity-100 cursor-pointer py-2"
                                @click="memberControl('devices')"
                                title="Saved Devices">                                
                                <Icon id="mobile" class="h-7 w-7 mx-auto" />
                            </div>
                            <div class="w-1/4 flex-grow text-center opacity-80 hover:opacity-100 cursor-pointer mn3-alert rounded-tl py-2" 
                                @click.prevent="$authapi.logout"
                                title="Logout">                                
                                <Icon id="logout" class="h-7 w-7 mx-auto" />
                            </div>
                        </div>
                    </div>                   

                    <!--Related account select-->
                    <div v-else class="w-full flex flex-col mn3-content pb-0 rounded-b">
                        <div class="text-sm uppercase flex flex-row font-bold items-center justify-center py-2 px-4 leading-3 cursor-pointer"
                            @click="showAccountSelect = false">
                            <Icon :id="hasSubAccounts ? 'user-group' : 'user-cirlce'" class="h-8 w-8 mr-2" />
                            <div>Account Select</div>
                            <Icon id="chev-up" class="h-6 w-6" />
                        </div>
                        <div v-for="acct in relatedAccounts" :key="acct.name"
                                class="w-full pr-3 py-2 opacity-80 hover:pr-4 hover:opacity-100 hover:font-bold px-2 leading-4 duration-300 cursor-pointer flex justify-end items-center"
                                @click="manageAccount(acct.id)"> 
                                <p v-if="acct.main" title="Main Account" class="font-bold flex-grow">*</p>
                                <div class="flex flex-col leading-3">
                                    <p :class="`${(acct.id === activeAccountId ? ' font-bold' : '')}`" 
                                        :title="`${(acct.id === activeAccountId ? 'Currently Managing This Account' : 'Manage This Account')}`">{{acct.name}}</p>
                                    <p class="font-mono text-xs text-right">{{acct.displayId}}</p>
                                </div>         
                                <div class="w-6 flex-shrink-0">
                                    <div :class="`${acct.id == activeAccountId ? 'opacity-100 border-2' : 'opacity-60 border'} h-5 w-5 text-xs rounded-full text-center font-bold ml-2`">
                                        <Icon :id="acct.id == activeAccountId ? 'user-solid' : 'user-line'" class="h-4 w-4 mx-auto" />
                                    </div>
                                </div>
                            </div>
                            <!--Logout-->
                            <div class="flex flex-row items-center justify-end mn3-alert rounded-b p-2 hover:pr-4 duration-300 cursor-pointer"
                                    @click.prevent="$authapi.logout">
                                <span class="font-bold uppercase">Logout</span>
                                <Icon id="logout" class="h-6 w-6 ml-2" />
                            </div>
                    </div>
                    </transition>
                </div>
            </div>

        </div>
        
        
    <!--Expanded custom links-->
    <transition name="from-left">
    <div v-if="showAllCustomLinks && $store.state.isLoggedInAny" class="w-96 z-[45] fixed mn3-navbar flex flex-col justify-start border-r-4 pt-28"
        :style="`height: ${dropdownHeight}`">
        <div @click="showAllCustomLinks = false;filter = '';"
            class="hidden w-full p-2 mn3-navbar-option opacity-80 hover:opacity-100 duration-100 flex flex-row items-center cursor-pointer leading-4 mb-4">
            <Icon id="x" class="h-8 w-8 mr-2" />
            <div>Close Menu</div>            
        </div>

        

        <div class="py-1 px-2 flex-grow-0 mb-2">     
                <div class="w-full py-1 px-1 flex flex-row  items-center justify-between mn3-content-alt border rounded-full">
                    <label class="flex flex-row items-center flex-grow">
                        <Icon id="search" class="h-5 w-5 mr-2" />
                        <input type="text" class="mn3-content-alt flex-grow" placeholder="Filter" v-model="filter"
                            @keydown.esc="filter = ''" />
                        <div @click="filter = ''" :class="`${filter == '' ? 'mn3-contet opacity-20' : 'mn3-cancel opacity-100' } p-1 h-6 text-xs rounded-full text-center text-sm ml-2 mr-1 font-bold`">CLEAR</div>
                    </label>
                </div>
            </div>   

        <!--Grouped-->
        <div v-if="groups.length > 0"
            class="w-full flex flex-col overflow-y-auto"
            style="direction: rtl">
            <div v-for="(group, i) in groups" :key="group">
                <div style="direction: ltr" 
                    :class="`${i > 0 ? 'mt-5' : ''} font-bold px-3 uppercase`">{{group.attributes.titleUse}}</div>
                <div @click="navClicked(link.attributes.url)"
                    v-for="link in additionalNavByGroup(group.id)"
                    :key="link.attributes.url" 
                    style="direction: ltr"
                    :class="`${filter.length > 0 && link.attributes.title.toLowerCase().indexOf(filter.toLowerCase()) == -1 ? 'hidden' : ''} w-full p-1 px-3 mn3-navbar-option opacity-80 hover:opacity-100 duration-100 flex flex-row items-center cursor-pointer leading-4 flex-grow-0`">
                    <Icon :id="link.attributes.icon" class="h-8 w-8 mr-2" />
                    <div>{{link.attributes.title}}</div>  
                </div>

            </div>            
        </div>
        <!--Ungrouped-->
        <div v-else class="w-full flex flex-col overflow-y-auto"
            style="direction: rtl">
            <div @click="navClicked(link.to)"
                v-for="link in links"
                :key="link.attributes.url" 
                style="direction: ltr"
                :class="`${filter.length > 0 && link.attributes.title.toLowerCase().indexOf(filter.toLowerCase()) == -1 ? 'hidden' : ''} w-full p-2 px-3 mn3-navbar-option opacity-80 hover:opacity-100 duration-100 flex flex-row items-center cursor-pointer leading-4 flex-grow-0`">
                <Icon :id="link.attributes.icon" class="h-8 w-8 mr-2" />
                <div>{{link.attributes.title}}</div>  
            </div>
        </div>
        <div class="flex-grow flex-shrink-0 " />
            
        <div @click="navClicked('/links');showAllCustomLinks = false" class="mn3-navbar-option p-2 text-center mt-10 cursor-pointer opacity-80 hover:opacity-100 duration-100 text-sm font-bold flex-grow-0 mb-10">View Full Services Screen</div>
        
    </div>
    </transition>
    
    </div>
</template>

<script>

import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { ref } from "vue";


import Icon from '@/components/Icon.vue'
import LinkTile from '@/components/LinkTile.vue'
import Notice from '@/components/Notice.vue'

export default {
    components: {
        Icon,
        LinkTile,
        Notice
    },
    props: {
        mainNav:{            
            type: Object,
            default: []
        },
        secondaryNav:{
            type: Object,
            default: []
        },
        additionalNav:{
            type: Object,
            default: []
        },
        customLinks:{
            type: Object,
            default: []
        },
        hasNotch: {
            type: Boolean,
            default: false
        },
        isBlank: {                                    //isBlank: Show without any links, used on login screens           
            type: Boolean,
            default: false
        },
        links: {                                      //links: Links displayed in topnav
            type: Object,
            default: []
        }
    },
    computed:{
        //Returns array of all groups used in additional nav
        additionalNavGroups(){
            let groups = [];
            for(var ii = 0; ii < this.$props.additionalNav.length; ii++){
                let opt = this.$props.additionalNav[ii];
                if(opt.group != undefined && !groups.includes(opt.group)){
                    groups.push(opt.group)
                }
            }
            return groups;
        },
        dropdownHeight(){
            return `${window.innerHeight}px`;
        },
        hasSitewideAlert(){
            if(!this.$store.state.fi.alert || !this.$store.state.fi.alert.alert || this.$store.state.fi.alert.alert.length == 0)
                return false;

            return true;
        },        
        rdcEnabled(){            
			let mship = this.$store.state.memberships.find((el) => el.id.split('/').pop() == this.$store.state.membershipId);
			let membStatus = "A";
			if(mship)
				membStatus = mship.attributes.status;			

            return this.$store.state.fi.rdcEnabled && membStatus != 'R' && membStatus != 'B'
        }
    },
    methods:{
        mainNavClicked(screen){        
            switch(screen){

                case 'accounts':
                    this.$router.push('/accounts');
                break;
                
                case 'transfer':
                    this.$router.push('/transfer');
                break;
                
                case 'statements':
                    this.$router.push('/statements');
                break;

                case 'allied':
                    this.$router.push('/allied');
                break;
                
                case 'allied2':
                    this.$router.push('/allied2');
                break;
                
                case 'ipay':
                    this.$router.push('/ipay');
                break;
                
                case 'neuralpay':
                    this.$router.push('/neuralpay');
                break;
            }
            return;
        },
        mainNavIcon(screen){        
            switch(screen){

                case 'accounts':
                return 'dollar';
                
                case 'transfer':
                return 'transfer';
                
                case 'statements':
                return 'document';

                case 'allied':
                case 'allied2':
                case 'ipay':
                case 'neuralpay':
                return 'ticket-2';

            }
            return screen;
        },
        mainNavTitle(screen){
            switch(screen){

                case 'allied':
                return this.$store.state.fi.alliedTitle;

                case 'allied2':
                return this.$store.state.fi.allied2Title;
                
                case 'neuralpay':
                return this.$store.state.fi.neuralpayTitle;

                case 'accounts':
                return 'Accounts';
                
                case 'transfer':
                return 'Transfer';
                
                case 'statements':
                    if(this.$store.state.fi.statementsEnabled && this.$store.state.fi.noticesEnabled )
                        return 'Statements & Notices';                
                    else if(this.$store.state.fi.statementsEnabled)
                        return 'Statements';                
                    else if(this.$store.state.fi.noticesEnabled)
                        return 'Notices';  
            }
            return screen;
        },
        additionalNavByGroup(group){
            let opts = [];
            for(var ii = 0; ii < this.links.length; ii++){
                if((group == '' && this.links[ii].attributes.group == undefined) || (this.links[ii].attributes.group == group))
                    opts.push(this.links[ii]);
            }
            return opts;
        },
        async loadLinksAndGroups(){
        
            let links_page = await this.$pfapi.get('links_page');
            this.links = links_page.data.data

            let links_page_groups = await this.$pfapi.get('links_page_groups');
            this.groups = links_page_groups.data.data;
            this.groups.push({
                id: '',
                attributes: {
                    title: ''
                }
            })

            for(var ii = 0; ii < this.groups.length; ii++){
                this.groups[ii].attributes.titleUse = this.groups[ii].attributes.title.split('__')[0];
            }
        },
        memberControl(id){
            switch(id){
                case 'logout':
                this.$authapi.logout;
                break;

                case 'devices':
                this.$router.push('/devices');
                break;

                case 'messages':
                this.$router.push('/messages');
                break;

                case 'settings':
                this.$router.push('/settings');
                break;
            }
        }
    },
    created(){
        this.loadRelatedAccounts();
        if(this.$store.state.fi.linksPageEnabled)
            this.loadLinksAndGroups();
    },
    setup(props, { emit }){

        const router = useRouter();             //router: Router ref   
        const store = useStore();               //store: Store ref   

        /* --Variables-- */
        const activeAccountId                   //activeAccountId: ID of currently active account
            = ref(store.state.membershipId)
        const activeAccountName = ref("")       //activeAccountName: Name of currently active account
        const hasSubAccounts = ref(false)  //hasSubAccounts: If related accounts selection menu is open
        const relatedAccounts = ref({})         //relatedAccounts: related accounts that can be managed  
        const showAccountSelect = ref(false)    //showAccountSelect: If account selection is active, true if any related accounts
        const showAllCustomLinks = ref(false)
        const logo = ref(store.state.theme.headerLogoUrl);
        const filter = ref('');
        const unread = ref(0);
        const links = ref([]);
        const groups = ref([]);

        /* --Computed-- */
        
        /* --Functions-- */
        // loadRelatedAccounts: Load related sub accounts that can be managed
        function loadRelatedAccounts(){
            relatedAccounts.value = {};
            let data = JSON.parse(JSON.stringify(store.state.memberships))
            let activeFound = false;
            let mainId = 0;
            let mainName = '';

            for(var ii = 0; ii < data.length; ii++){
                let atrb =  {...data[ii]}["attributes"]            

                //Check for setting default account
                if(atrb.id == activeAccountId.value){
                    activeAccountName.value = atrb.title
                    activeFound = true;
                }
                    
                if(atrb.default){
                    mainId = atrb.id
                    mainName = atrb.title
                }
                
                relatedAccounts.value[atrb.id] = {name: atrb.title, notice: 0, default: atrb.default, id: atrb.id, displayId: atrb.displayId};
                hasSubAccounts.value = true;
            }

            //If no active account, set to default
            if(!activeFound){
                activeAccountId.value = mainId
                activeAccountName.value = mainName
            }

            //Check for returning to related account on reload            
            if(localStorage['membership_id'] != undefined && localStorage['membership_id'] != ''){
                let restoreId =  localStorage['membership_id'];
                if(restoreId != activeAccountId.value && relatedAccounts.value[restoreId] != undefined
                    && store.state.membershipId != restoreId){
                    this.manageAccount(restoreId);
                }
            }
            
        }

        // manageAccount: Switch which account is being managed
        //  id: id of account to manage
        function manageAccount(id){

            //Check that isnt current acount
            if(store.state.membershipId == id){
                return;
            }
            else{
                store.commit("setMembershipId", id);
                localStorage['membership_id'] = id;
                activeAccountId.value = id
                activeAccountName.value = relatedAccounts.value[id].name
                loadRelatedAccounts()
                showAccountSelect.value = false
                router.push('/accounts')
            }
        }

        // navClicked: Go to a header link or reload same screen
        //  path: path to load
        function navClicked(path){

            //Close custom links if open
            //showAllCustomLinks.value = false;

            //Open an external link
            if(path.indexOf('http://') != -1 || path.indexOf('https://') != -1){
                window.open(path, '_blank');
            }
            //Go to a screen
            else{
                let current = router.currentRoute['_rawValue'].path;
                if(current == path){
                    router.go();
                }else{
                    router.push(path);
                }
            }
        }

        // navbarOptionClasses: Classes applied to topnav options
        const navbarOptionClasses = function(screen, theme){
            let classes = `mn3-navbar-option${theme != undefined ? '-'+theme : ''} font-bold text-sm hover:relative hover:bottom-1 
            cursor-pointer h-12 transition duration-150 ease-in-out leading-4 items-center justify-center`;
            if(screen == store.state.currentPage){
                classes += ' mn3-navbar-active ';
            }
            return classes;
        };

        const expandedOptionClasses = ref(` font-bold text-sm px-3 hover:relative hover:bottom-1 
            cursor-pointer h-16 transition duration-150 ease-in-out leading-4 flex flex-col items-center justify-center`);

        return {
            activeAccountId,
            activeAccountName,
            relatedAccounts,
            emit, 
            expandedOptionClasses,
            filter,
            groups,
            navClicked,
            hasSubAccounts,
            links,
            loadRelatedAccounts,
            logo,
            manageAccount,
            navbarOptionClasses,
            showAccountSelect,
            showAllCustomLinks,
            store,
            unread
        }
    }
}
</script>



<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown:hover .dropdown-menu {
  display: block;
}</style>
