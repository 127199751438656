<template>
    <div class="w-full flex flex-col justify-start">
        <div class="hidden">{{ membershipIdChanged }}</div>
        <div class="w-full text-center mb-2">
            <div class="w-full flex flex-row items-center justify-center text-center md:max-w-screen-sm mx-auto md:rounded overflow-hidden cursor-pointer">
                <img v-if="$store.state.theme.summaryImageUrl" :src="$store.state.theme.summaryImageUrl" :title="$store.state.theme.summaryImageAltText"
                    @click="advertClicked" />
                <img v-else :src="$store.state.theme.mainLogoUrl" class="mx-auto" style="max-height: 175px"/>
            </div>
        </div>
        <div class="flex flex-col lg:w-full flex-shrink-0 flex-grow-0">
            <div v-if="reloadError" class="w-full text-center">
                Your session timed out, please reload this screen.
            </div>
            <Table
                v-else
                id="accounts"
                ref="accounts"
                title="Accounts"
                table="accounts"
                :recordKey="recordKey"
                class="w-full"
                rowClass="cursor-pointer mn3-content-hover"
                rowClickEmit="loadAccount"
                @loadAccount="goToAccount"
                :hasQuickFilter="true"
                :hasFullFilter="true"
                :recordsLoading="recordsLoading"
                :showRecordCount="false"
                :filterBy="[
                {
                label: 'Title',
                key: 'desc',
                classes: 'md:w-1/2',
                },
                {
                label: 'Desc',
                key: 'subDesc',
                classes: 'md:w-1/2',
                },
                {
                label: 'Rate',
                key: 'interestRate',
                type: 'numeric',
                classes: 'md:w-1/2',
                },
                {
                label: 'Current Balance',
                key: 'availableBalance',
                type: 'numeric',
                classes: 'md:w-1/2',
                },
                ]"
                :showSort="false"
                :wrapWhenSmall="true"
                :records="records"
                >
            </Table>
        </div>
    </div>
</template>
<script>
    import { ref } from "vue";
        
    import Field from "@/components/Field.vue";
    import Form from "@/components/Form.vue";
    import Icon from "@/components/Icon.vue";
    import Table from "@/components/Table.vue";
    
    export default {
      name: "Accounts",
      components: {
        Field,
        Form,
        Table,
        Icon
      },
      computed: {
        /* Load accounts for the new membership id when changed */
        membershipIdChanged: async function () {
            // Included to trigger computed prop
            let newId = this.$store.state.membershipId; 
            if(this.loadedId != newId && this.loadedId != 0){
                let id = await this.loadAccounts();
                this.loadedId = id;
                return id;
            }
            return -1;
        },
      },
      methods: {   
        advertClicked(){
            if(this.$store.state.theme.summaryImageLinkUrl){
                window.open(this.$store.state.theme.summaryImageLinkUrl);
            }
        },
        /* Return the icon for account type */
        accountTypeToIcon(type) {
          if (
            type.indexOf("mortgage") != -1 ||
            type.indexOf("home") != -1 ||
            type.indexOf("house") != -1
          )
            return "home"
          else if (
            type.indexOf("car") != -1 ||
            type.indexOf("vehicle") != -1 ||
            type.indexOf("automobile") != -1
          )
            return "truck"
    
          switch (type.toLowerCase()) {
            case "creditcard":
              return "credit-solid"
              
            case "investment":
              return "dollar"
    
            case "checking":
              return "check"
    
            case "hsa":
              return "heart"

            case "lineofcredit":
              return "credit"
    
            case "loan":
              return "ticket"
    
            case "mortgage":
              return "home"
    
            case "savings":
              return "dollar"
          }
    
          return "home";
        },        
        /* Formats fetched accounts data to be read by the table component */
        formatFetchedData(raw) {
          //Records holder
          let records = []
    
          for (var ii = 0; ii < raw["data"].length; ii++) {
            let rec = raw["data"][ii]["attributes"]
    
              records.push({
                type: rec["type"],
                icon: this.accountTypeToIcon(rec["type"]),
                subDesc: rec["displayAccountId"],
                desc: rec["nickname"] != undefined ? rec["nickname"] : rec["description"],
                id: rec["accountId"],
                interestRate: rec["interestRate"] != undefined ? rec["interestRate"] : "",
                nextPaymentDate: rec["nextPaymentDate"] != undefined ? rec["nextPaymentDate"] : "",
                nextPaymentAmount: rec["nextPaymentAmount"] != undefined ? rec["nextPaymentAmount"] : "",
                maturityDate: rec["maturityDate"] != undefined ? rec["maturityDate"] : "00/00/00",
                availableBalance: rec["availableBalance"],
                amountDescA: "",
                actualBalance: rec["actualBalance"] != undefined ? rec["actualBalance"] : "",
                amountDescB: "",
                external: rec["external"],
                canViewHistory: rec["canViewHistory"],
                externalOwnerName: rec["externalOwnerName"],
              })
          }        
          let data = { records: records }    
          return data
        },
        /* Go to the selected account screen */
        goToAccount(ev, key) {
          const id = this.records[key].id
          this.$router.push("/account/" + id)
        }, 
        async loadAccounts(){           
            //Fetch accounts
            try {
            const data = await this.$mapi.get("accounts");
            let formattedData = this.formatFetchedData(data.data);
            this.loadedId = this.$store.state.membershipId;
            this.setAccountsData(formattedData);

            this.recordsLoading = false;
            } 
            // Catch invalid response from server, force inactive logout
            catch (ex) {
            console.log("Reload Error Catch: ", ex);
            this.reloadError = true;
            this.setAccountsData({ records: [] });     
            this.recordsLoading = false;  

            //Force inactive logout
            this.$store.commit('setShowInactiveNotice', true);           
            this.$authapi.logout();
            }
            return this.$store.state.membershipId;
        },
        /* Set records to fetched data and re-render */
        setAccountsData(data) {
          this.records = data.records
          this.recordKey += 1
        }
      },
      async created() {
        document.title = "Accounts"
      },
      async mounted(){
        await this.loadAccounts();
        this.initialLoadComplete = true;
      },    
      setup() {    
        const loadedId = ref(0);          //ID of loaded membership
        const recordKey = ref(1);         //Used to re-render table
        const records = ref("");          //User's accounts
        const reloadError = ref(false);   //If records failed to load due to user inacvitivy
        const recordsLoading = ref(true)
        const initialLoadComplete = ref(false)

        return {
        initialLoadComplete,
          loadedId,
          records,
          reloadError,
          recordKey,
          recordsLoading
        };
      },
    };
</script>
