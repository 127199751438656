<template>
    <div class="max-w-screen-lg flex flex-col w-full pb-10">
        <div class="font-bold text-xl mn3-content border rounded-sm p-2">Additional Services</div>
            <div class="py-1 px-2 mt-2">     
                <div class="w-full py-1 px-1 flex flex-row items-center justify-between mn3-content-alt border rounded-full">
                    <label class="flex flex-row items-center flex-grow">
                        <Icon id="search" class="h-5 w-5 mr-2" />
                        <input type="text" class="mn3-content-alt flex-grow" placeholder="Filter" v-model="filter"
                            @keydown.esc="filter = ''" />
                        <div @click="filter = ''" :class="`${filter == '' ? 'mn3-contet opacity-60' : 'mn3-cancel opacity-100' } p-1 h-6 w-6 text-xs rounded-full text-center text-sm ml-2 font-bold`">X</div>
                    </label>
                </div>
            </div>   
            
            <div class="w-full flex flex-row flex-wrap">


                <div v-for="(group, key) in groups" :key="key"
                    class="flex flex-col w-full mt-2">
                    <!--Group Header-->
                    <div class="flex flex-row items-center">
                        <Icon :id="group.attributes.icon" class="h-10 w-10 mr-2"/>
                        <span class="font-bold text-lg uppercase">{{group.attributes.titleUse}}</span>
                        <!--Slideshow Icons-->
                        <div v-if="group.mode == 'slideshow' && filter == ''"
                            class="flex flex-row items-center ml-5">
                            <div v-for="i in groupLinks(group.id).length" :key="i" 
                                :title="groupLinks(group.id)[i-1].attributes.titleUse"
                                @click="selectSlide(group, i-1)"
                                :class="`${(i-1 == group.active) ? 'opacity-100' : 'opacity-50'} cursor-pointer mn3-content-alt rounded-full w-5 h-5 mr-2`">
                                <Icon v-if="i-1 == group.active && timers[group.id] == undefined" id="play" class="p-1 h-5 w-5" />    
                            </div>
                        </div>
                    </div>
                    <div :class="`${(group.mode == 'slideshow' && filter == '') ? 'h-40 overflow-hidden' : ' flex-wrap'} flex flex-row flex-wrap w-full`">                        
                        <div v-for="(l, i) in groupLinks(group.id)" :key="l.attributes.url"                            
                            :class="`${filter.length > 0 && l.attributes.title.toLowerCase().indexOf(filter.toLowerCase()) == -1  ? 'hidden' : ''} 
                                ${tileWidth(l)}`">
                                <!--${group.mode == 'slideshow' && i != group.active ? 'hidden' : ''} -->
                            <transition name="slideshow" mode="out-in">
                                <LinkTile v-if="!(group.mode == 'slideshow' && filter == '' && i != group.active)"
                                    @click="tileClicked(l.attributes.url)"
                                    :theme="l.attributes.style != undefined ? l.attributes.style : group.style "
                                    :title="l.attributes.title"
                                    :description="l.attributes.description"
                                    :icon="l.attributes.icon"
                                    :size="l.attributes.size"
                                    :height="group.tall ? '60' : '40'"
                                    />
                            </transition>
                        </div>
                    </div>
                    
                    <div v-if="group.mode == 'slideshow'"
                        class="w-full px-1">
                        <div :class="`${timers[key] == undefined ? 'opacity-0' : ''} duration-150 w-full rounded-b flex justify-start overflow-hidden h-2`">
                            <div class="h-2 mn3-content-alt flex-shrink-0" 
                                :style="`transition-duration: 1100ms; transition-timing-function: linear; width: ${group.slideProgress}%`" />
                            <div class="h-2 flex-grow mn3-content-alt opacity-50" />
                        </div>                    
                    </div>
                </div>
            </div>

    </div>
</template>
<script>
    import { ref, computed } from 'vue'

    import Icon from '@/components/Icon.vue'
    import LinkTile from '@/components/LinkTile.vue'
    import Slideshow from '@/components/Slideshow.vue'

export default{
    components:{
        Icon,
        LinkTile,
        Slideshow
    },
    async created(){
        document.title= 'Links';
        if(this.$store.state.fi.linksPageEnabled)
            this.loadLinksAndGroups();
        
        //let links_page = await this.$pfapi.get('links_page');
        //this.links = links_page.data.data
    },
    async mounted(){     
        this.setupSlideshows();
    },
    async unmounted(){
        this.stopAllSlideshowTimers();
    },
    methods: {
        async loadLinksAndGroups(){
        
            this.stopAllSlideshowTimers();

            let links_page = await this.$pfapi.get('links_page');
            this.links = links_page.data.data

            let links_page_groups = await this.$pfapi.get('links_page_groups');
            this.groups = this.parseGroupData(links_page_groups.data.data);
            this.setupSlideshows();
        },
        openLink(link){
            window.open(link)
        },
        parseGroupData(data){

            let ret = {};

            for(var ii = 0; ii < data.length; ii++){

                let g = data[ii];

                //Parse slideshow setting from name
                let split = g.attributes.title.split('__');
                g.attributes.titleUse = split[0];
                if(split.length > 1 && split[1] == 'slide'){
                    g.mode = 'slideshow';
                    g.delay = 5;
                    g.active = 1;
                    g.size = 3;
                }

                ret[g.id] = g;                            
            }


            return ret;
        },
        selectSlide(group, i){

                        //If already active slide and paused, go to next slide + start slideshow again
            if(group.active == i && this.timers[group.key] == undefined){
                
                this.groups[group.key].active++;
                let count = this.groupLinks(group.key).length;
                if(this.groups[group.key].active >= count){
                    this.groups[group.key].active = 0;
                }
                this.startSlideshow(group.key);
            }
            //Otherwise set to slide and stop slideshow
            else{
                group.active = i;

                //Reset time for next slide + make 3x as long
                this.stopAllSlideshowTimers();
            }
                        

        },
        // Returns the width: xx%; rule for a slideshow progress bar
        slideshowProgress(group){
            if(group == undefined) return '';

            //Perc figure: (Now - group.start) / (group.delay * 1000)
            let perc = (Date.now() - group.startTime) / (group.delay * 10) * 1.3;
            group.slideProgress = perc;
            
            return;
        },
        startSlideshow(key){

            let root = this;
            //Start timer                
            root.timers[key] = window.setInterval(function(){                
                root.groups[key].startTime = Date.now();
                root.groups[key].active++;
                let count = root.groupLinks(key).length;
                if(root.groups[key].active >= count){
                    root.groups[key].active = 0;
                }
            }, root.groups[key].delay * 1000);

            window.setInterval(function(){      
                root.slideshowProgress(root.groups[key])
            }, 1000);

        },
        stopAllSlideshowTimers(){
            //Stop all slideshow timers
            for(var key in this.timers){
                let timer = this.timers[key];
                clearInterval(timer);
                this.timers[key] = undefined;
            }
        },        
        tileClicked(url){
            if(url == undefined) return;

            if(url.indexOf('http://') != -1 || url.indexOf('https://') != -1){
                window.open(url);
            }
            else
            this.$router.push(url);

        },
        tileWidth(service){
            if(service.attributes.size == 3){
                return 'w-full';
            }
            else if(service.attributes.size == 2){
                return 'w-full sm:1/2 lg:w-1/2 flex-grow';
            }
            else return 'w-1/3 md:w-1/4 lg:w-1/5 flex-grow';
        },
        //Setup timers so slideshows automatically play
        setupSlideshows(){
        
            
            let root = this;

            for(var key in root.groups){
                let g = root.groups[key];
                
                if(g.mode == 'slideshow'){
                    let key2 = g.id + '';
                    this.startSlideshow(key2);
                }
            }

        },
        groupLinks(groupId){
            let links = [];

            for(var ii = 0; ii < this.links.length; ii++){
                if(this.links[ii].attributes.group == groupId)
                    links.push(this.links[ii]);
            }

            return links;
        }
    },
    setup(){
        const filter = ref('');
        const links = ref([]);
        const groups = ref([]);
        const timers = ref([]);
       
        return {
            filter,
            groups,
            links,
            timers
        }
    }
}
</script>